.Header {
    display: grid;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100vw;
    top: 0;
    /* Stick it to the top */
    max-height: 120px;
    grid-template-areas: "logo logo2 navgauche navdroite";
    /* Cosmetics */
}

.Logo {
    grid-area: logo;
    padding: 10px 0 0 30px;
    height: 100px;
}

.Logo2 {
    grid-area: logo2;
    padding: 10px 0 0 20px;
    height: 100px;
}

.Nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
}

.NavDroit {
    margin-right: 20px;
    grid-area: navdroite;
    grid-template-columns: repeat(2, auto);
}

.Nav a {
    color: lightgray;
    font-size: 22px;
    font-family: cursive;
    font-weight: 500;
    transition: 0.5s;
    text-decoration: none;
    padding-right: 10px;
    padding-left: 10px;
}

.Nav a:hover {
    color: black;
    transform: scale(1.1);
}

.Nav a:active {
    color: black;
}

.NavGauche {
    margin-right: auto !important;
    grid-area: navgauche;
    grid-template-columns: repeat(3, auto);
}


.Expand {
    display: none;
    grid-area: expand;
    margin: 0 20px 0 0;
    padding: 0;
    justify-self: end;
    font-size: 40px;
    border: none;
    background: none;
    outline: none;
    transition: 0.1s;
}

.Expand:active {
    transform: scale(1.2);
}

.NavAnimation-enter {
    opacity: 0;
    transform: scale(0.5);
}

.NavAnimation-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 350ms, transform 350ms;
}

.NavAnimation-exit {
    opacity: 1;
}

.NavAnimation-exit-active {
    opacity: 0;
    transform: scale(0.5);
    transition: opacity 350ms, transform 350ms;
}