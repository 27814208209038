.PageSite {
  background: url("../../images/PageBackground.png") no-repeat center center fixed;
  background-size: cover;
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(6px + 2vmin);
  color: white;
}
h1 {
  font-style: oblique;
  text-decoration: underline;
}

.Titre {
  top: 0px;
}

.SubTitre {
  max-width: 800px;
  margin: 0 auto 10px auto;
  align-content: center;
  text-align:center;
  text-decoration: underline;
}

.Contenu {
  max-width: 800px;
  margin: 0 auto 10px auto;
  align-content: center;
  text-align:center;
}

.Galerie img {
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
  max-width:300px;
  max-height:200px;
  width: auto;
  height: auto;
  border: 1px solid aqua;
}

