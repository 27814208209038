.Footer {
    position: fixed;
    width: 100vw;
    height: 100px;
    bottom: 0;
}

.Copyright {
    float: right;
    margin-left: auto; 
    margin-right: 30px;
    color: lightgray;
    font-size: 16px;
    font-family: cursive;
    font-weight: 500;
}